import { Typography, Card, CardContent } from '@material-ui/core';
import { Title } from 'react-admin';

const Dashboard = () => (
    <Card>
        <Title title = "CloudFactory WorkSpaces Portal" />
        <CardContent>
            <img src='/cloud-factory-logo_SVG.svg' alt="CloudFactory Logo" />
            <Typography variant="h6">
                Welcome to the CloudFactory WorkSpaces Portal
            </Typography>
            <Typography variant="body1">
                Select a view on the left to begin...
            </Typography>
        </CardContent>
    </Card>
);

export default Dashboard;