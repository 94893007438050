import * as React from "react";
import { useState, cloneElement } from "react";
import { List, Show, Filter, useDataProvider, Confirm, Pagination, 
        ChipField, TabbedShowLayout, Tab, TextField, BooleanField,
        ReferenceField, TextInput, SelectInput, Button, BooleanInput,
        ReferenceInput, ExportButton, sanitizeListRestProps, ShowButton, 
        downloadCSV, DateField, useListContext, TopToolbar,
        AutocompleteInput } from "react-admin";
import jsonExport from "jsonexport/dist";
import { RotateRight, Restore, PlayArrow, Stop, DeleteForever, Launch } from '@material-ui/icons';
import { makeStyles, Chip } from '@material-ui/core';
import { WorkspaceShowActions } from './Helpers/workspaceShowActions.js';
import CustomizableDatagrid from 'ra-customizable-datagrid';

import WorkspaceModifyButton from "./Helpers/WorkspaceModifyButton.js"
import WorkspaceMigrateButton from "./Helpers/WorkspaceMigrateButton.js"

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};

const WorkspaceFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Workspace ID" source="id" />
        <TextInput label="User Name" source="UserName" />
        <TextInput label="Computer Name" source="ComputerName" />
        <TextInput label="Project Name" source="Tags_Project Name" />
        <TextInput label="Cost Code" source="Tags_Project" />
        <SelectInput label="State" source="State" choices={[
            {id: 'AVAILABLE', name: 'AVAILABLE'},
            {id: 'PENDING', name: 'PENDING'},
            {id: 'IMPAIRED', name: 'IMPAIRED'},
            {id: 'UNHEALTHY', name: 'UNHEALTHY'},
            {id: 'REBOOTING', name: 'REBOOTING'},
            {id: 'STARTING', name: 'STARTING'},
            {id: 'REBUILDING', name: 'REBUILDING'},
            {id: 'RESTORING', name: 'RESTORING'},
            {id: 'MAINTENANCE', name: 'MAINTENANCE'},
            {id: 'ADMIN_MAINTENANCE', name: 'ADMIN_MAINTENANCE'},
            {id: 'TERMINATING', name: 'TERMINATING'},
            {id: 'SUSPENDED', name: 'SUSPENDED'},
            {id: 'UPDATING', name: 'UPDATING'},
            {id: 'STOPPING', name: 'STOPPING'},
            {id: 'STOPPED', name: 'STOPPED'},
            {id: 'ERROR', name: 'ERROR'}
        ]} />
        <SelectInput label="Operating System" source="WorkspaceProperties_OperatingSystemName" choices={[
            {id: 'WINDOWS_11', name: 'Windows 11'},
            {id: 'WINDOWS_10', name: 'Windows 10'},
            {id: 'UBUNTU_22_04', name: 'Ubuntu 22.04'},
            {id: 'AMAZON_LINUX_2', name: 'Amazon Linux 2'}
        ]} />
        <SelectInput label="Protocol" source="WorkspaceProperties_Protocols" choices={[
            {id: 'PCoIP', name: 'PCoIP'},
            {id: 'WSP', name: 'WSP'}
        ]} />
        <SelectInput label="Compute Type" source="WorkspaceProperties_ComputeTypeName" choices={[
            {id: 'VALUE', name: 'Value'},
            {id: 'STANDARD', name: 'Standard'},
            {id: 'PERFORMANCE', name: 'Performance'},
            {id: 'POWER', name: 'Power'},
            {id: 'POWERPRO', name: 'PowerPro'}
        ]} />
        <SelectInput label="Running Mode" source="WorkspaceProperties_RunningMode" choices={[
            {id: 'ALWAYS_ON', name: 'AlwaysOn'},
            {id: 'AUTO_STOP', name: 'AutoStop'}
        ]} />
        <BooleanInput source="Connected" defaultValue={true} />
        <SelectInput label="Region" source="region" choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
        <ReferenceInput label="Directory Name" source="DirectoryId" reference="directories" perPage={100}>
            <AutocompleteInput optionText="Alias" />
        </ReferenceInput>
        <SelectInput label="Automation Tag" source="Tags_Automation" choices={[
            {id: 'Managed', name: 'Managed'},
            {id: 'Manual', name: 'Manual'}
        ]} />
        <QuickFilter source="ClientIpAddress" label="Last Login from Maximus Network" defaultValue={"172.81.80.0/22"} />
    </Filter>
);

const WorkspacePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const WorkspaceBulkActions = ({ permissions, basePath, selectedIds, resource }) => {
    const dataProvider = useDataProvider();

    const [rebootOpen, setRebootOpen] = useState(false);
    const [startOpen, setStartOpen] = useState(false);
    const [stopOpen, setStopOpen] = useState(false);
    const [restoreOpen, setRestoreOpen] = useState(false);
    const [rebuildOpen, setRebuildOpen] = useState(false);
    const [terminateOpen, setTerminateOpen] = useState(false);

    const handleRebootClick = () => setRebootOpen(true);
    const handleRebootDialogClose = () => setRebootOpen(false);
    const handleRebootConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Reboot"}, {}).then(results => (alert(results.data)));
        setRebootOpen(false);
    }

    const handleStartClick = () => setStartOpen(true);
    const handleStartDialogClose = () => setStartOpen(false);
    const handleStartConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Start"}, {}).then(results => (alert(results.data)));
        setStartOpen(false);
    }

    const handleStopClick = () => setStopOpen(true);
    const handleStopDialogClose = () => setStopOpen(false);
    const handleStopConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Stop"}, {}).then(results => (alert(results.data)));
        setStopOpen(false);
    }

    const handleRestoreClick = () => setRestoreOpen(true);
    const handleRestoreDialogClose = () => setRestoreOpen(false);
    const handleRestoreConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Restore"}, {}).then(results => (alert(results.data)));
        setRestoreOpen(false);
    }

    const handleRebuildClick = () => setRebuildOpen(true);
    const handleRebuildDialogClose = () => setRebuildOpen(false);
    const handleRebuildConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Rebuild"}, {}).then(results => (alert(results.data)));
        setRebuildOpen(false);
    }

    const handleTerminateClick = () => setTerminateOpen(true);
    const handleTerminateDialogClose = () => setTerminateOpen(false);
    const handleTerminateConfirm = () => {
        dataProvider.bulkaction(resource, {ids: selectedIds, action: "Terminate"}, {}).then(results => (alert(results.data)));
        setTerminateOpen(false);
    }

    var buttonList = [];
    if (Array.isArray(permissions) && permissions.length > 0) {
        if (permissions.includes('RebootWorkspace') || permissions.includes('Super Admin')) {
            buttonList = ([
                <Button label="Reboot" onClick={handleRebootClick}><RotateRight /></Button>,
                <Confirm
                    isOpen={rebootOpen}
                    title="Reboot Workspace "
                    content="Are you sure you want to reboot these Workspaces?"
                    onConfirm={handleRebootConfirm}
                    onClose={handleRebootDialogClose}
                />
            ]);
        }
        if (permissions.includes('RestoreWorkspace') || permissions.includes('Super Admin')) {
            buttonList.push(<Button label="Restore" onClick={handleRestoreClick}><Restore /></Button>);
            buttonList.push(
                <Confirm
                    isOpen={restoreOpen}
                    title="Restore Workspace"
                    content="Are you sure you want to restore these Workspaces?"
                    onConfirm={handleRestoreConfirm}
                    onClose={handleRestoreDialogClose}
                />);
        }
        if (permissions.includes('RebuildWorkspace') || permissions.includes('Super Admin')) {
            buttonList.push(<Button label="Rebuild" onClick={handleRebuildClick}><Launch /></Button>);
            buttonList.push(
                <Confirm
                    isOpen={rebuildOpen}
                    title="Rebuild Workspace"
                    content="Are you sure you want to rebuild these Workspaces?"
                    onConfirm={handleRebuildConfirm}
                    onClose={handleRebuildDialogClose}
                />);
        }
        if (permissions.includes('StartStopWorkspace') || permissions.includes('Super Admin')) {
            buttonList.push([
                <Button label="Start" onClick={handleStartClick}><PlayArrow /></Button>,
                <Confirm
                    isOpen={startOpen}
                    title="Start Workspace"
                    content="Are you sure you want to start these Workspaces?"
                    onConfirm={handleStartConfirm}
                    onClose={handleStartDialogClose}
                />,
                <Button label="Stop" onClick={handleStopClick}><Stop /></Button>,
                <Confirm
                    isOpen={stopOpen}
                    title="Stop Workspace"
                    content="Are you sure you want to stop these Workspaces?"
                    onConfirm={handleStopConfirm}
                    onClose={handleStopDialogClose}
                />
            ]);
        }
        if (permissions.includes('ModifyWorkspace') || permissions.includes('Super Admin')) {
            buttonList.push(<WorkspaceModifyButton ids = {selectedIds}/>)
        }
        if (permissions.includes('MigrateWorkspace') || permissions.includes('Super Admin')) {
            buttonList.push(<WorkspaceMigrateButton ids = {selectedIds}/>)
        }
        if (permissions.includes('TerminateWorkspace') || permissions.includes('Super Admin')) {
            buttonList.push(<Button label="Terminate" onClick={handleTerminateClick}><DeleteForever /></Button>);
            buttonList.push(
            <Confirm
                isOpen={terminateOpen}
                title="Terminate Workspace"
                content="Are you sure you want to terminate these Workspaces?"
                onConfirm={handleTerminateConfirm}
                onClose={handleTerminateDialogClose}
            />);
        }
    }
    return (<TopToolbar>{buttonList}</TopToolbar>);
};

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
                exporter={exporter}
            />
        </TopToolbar>
    );
};

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'DirectoryId', 'directories').then(directories => {
        const workspacesForExport = records.map(record => {
            const { ProjectName, Tags_MaintenanceTest, UserVolumeEncryptionEnabled, RootVolumeEncryptionEnabled, 
                Connected, InSessionLatency, WorkspaceProperties_RunningModeAutoStopTimeoutInMinutes, 
                Tags_LastConnected, Tags_OperatingSystem, Tags_Name, ErrorCode, ErrorMessage, 
                Tags_Reboot, Tags_OrigProject, ...workspacesForExport } = record;
            workspacesForExport.RegistrationCode = directories[record.DirectoryId].RegistrationCode;
            workspacesForExport.ThinClientToken = directories[record.DirectoryId].ThinClientToken;
            workspacesForExport.DirectoryCategory = directories[record.DirectoryId].Category;
            workspacesForExport.MFA = directories[record.DirectoryId].MFA;

            return workspacesForExport;
        });
        jsonExport(workspacesForExport, {
            headers: ['id', 'UserName', 'region', 'Tags_Project Name', 'State', 'ComputerName', 'IpAddress',
                'WorkspaceProperties_ComputeTypeName', 'WorkspaceProperties_RunningMode', 'WorkspaceProperties_RootVolumeSizeGib',
                'WorkspaceProperties_UserVolumeSizeGib', 'WorkspaceProperties_OperatingSystemName', 'WorkspaceProperties_Protocols',
                'BundleId', 'DirectoryId', 'MFA', 'DirectoryCategory', 'RegistrationCode', 'ThinClientToken']
        }, (err, csv) => {
            downloadCSV(csv, 'workspaces');
        });
    });
};

export const WorkspaceList = ({ permissions, ...props}) => (
    <List {...props} sort={{ field: 'Tags_Project Name', order: 'ASC' }} actions={<ListActions exporter={exporter} maxResults={40000} {...props} />} 
            filters={<WorkspaceFilter />} pagination={<WorkspacePagination />} 
            bulkActionButtons={<WorkspaceBulkActions permissions={permissions} {...props} />} 
            title="All Workspaces">
        <CustomizableDatagrid defaultColumns={['id','UserName','Tags_Project Name','region','State','LoginTime']}>
            <TextField label="WorkSpace Id" source="id" />
            <TextField source="UserName" />
            <TextField source="ComputerName" />
            <TextField label="IP Address" source="IpAddress" />
            <TextField label="Client IP Address" source="ClientIpAddress" />
            <TextField label="Project Name" source="Tags_Project Name" />
            <TextField label="Cost Code" source="Tags_Project" />
            <TextField label="Compute Type" source="WorkspaceProperties_ComputeTypeName" />
            <TextField label="Running Mode" source="WorkspaceProperties_RunningMode" />
            <TextField label="Root Volume Size (Gb)" source="WorkspaceProperties_RootVolumeSizeGib" />
            <TextField label="User Volume Size (Gb)" source="WorkspaceProperties_UserVolumeSizeGib" />
            <TextField label="Operating System" source="WorkspaceProperties_OperatingSystemName" sortable={false} />
            <TextField label="Protocol" source="WorkspaceProperties_Protocols" sortable={false} />
            <TextField source="region" />
            <ChipField label="WorkSpace State" source="State" />
            <BooleanField source="Connected" />
            <TextField label="In Session Latency (ms)" source="InSessionLatency" />
            <DateField label="Created Time" source="CreateTime" showTime options={{timeZoneName: 'short'}}/>
            <DateField label="Last Login Time" source="LoginTime" showTime options={{timeZoneName: 'short'}}/>
            <ReferenceField label="Directory Name" source="DirectoryId" reference="directories" link={false}>
                <TextField source="Alias" />
            </ReferenceField>
            <ReferenceField label="MFA" source="DirectoryId" reference="directories" link={false}>
                <TextField source="MFA" />
            </ReferenceField>
            <ReferenceField label="Registration Code" source="DirectoryId" reference="directories" link={false}>
                <TextField source="RegistrationCode" />
            </ReferenceField>
            <TextField label="Automation Tag" source="Tags_Automation" />
            <ShowButton/>
        </CustomizableDatagrid>
    </List>
);

export const WorkspaceShow = props => (
    <Show {...props} actions={<WorkspaceShowActions />}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField label="WorkSpace Id" source="id" />
                <TextField source="UserName" />
                <TextField label="Project Name" source="Tags_Project Name" />
                <TextField source="region" />
                <ChipField label="WorkSpace State" source="State" />
                <DateField label="Created Time" source="CreateTime" showTime/>
                <DateField label="Last Login Time" source="LoginTime" showTime/>
                <ReferenceField label="Registration Code" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="RegistrationCode" />
                </ReferenceField>
                <ReferenceField label="Thin Client Token" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="ThinClientToken" emptyText="Not Applicable"/>
                </ReferenceField>
            </Tab>
            <Tab label="Properties" path="properties">
                <TextField label="Computer Name" source="ComputerName" />
                <TextField label="IP Address" source="IpAddress" />
                <TextField label="Bundle Id" source="BundleId" />
                <TextField label="Running Mode" source="WorkspaceProperties_RunningMode" />
                <TextField label="Autostop Timeout In Minutes" source="WorkspaceProperties_RunningModeAutoStopTimeoutInMinutes" emptyText="Not Applicable" />
                <TextField label="Compute Type" source="WorkspaceProperties_ComputeTypeName" />
                <TextField label="Protocol" source="WorkspaceProperties_Protocols" />
                <TextField label="Root Volume Size (Gb)" source="WorkspaceProperties_RootVolumeSizeGib" />
                <TextField label="User Volume Size (Gb)" source="WorkspaceProperties_UserVolumeSizeGib" />
                <TextField label="Operating System" source="WorkspaceProperties_OperatingSystemName" />
            </Tab>
            <Tab label="Directory" path="directory">
                <TextField label="Directory Id" source="DirectoryId" />
                <ReferenceField label="Alias" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="Alias" />
                </ReferenceField>
                <ReferenceField label="Category" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="Category"/>
                </ReferenceField>
                <ReferenceField label="Tenancy" source="DirectoryId" reference="directories" link={false}>
                    <TextField source="Tenancy" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);