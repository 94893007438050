import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import LoginForm from './LoginForm.js';

const styles = {
    main: {
        backgroundColor: '#545487',
        backgroundImage: 'url(./background_splash.jpeg)',
    },
};

const LoginPage = (props) => (
    <Login {...props} >
        <LoginForm />
    </Login>
);

export default (withStyles(styles)(LoginPage));