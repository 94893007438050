import Amplify, { API } from 'aws-amplify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const apiName = process.env.REACT_APP_API_NAME;
var sortField = "";
var sortOrder = "";

Amplify.configure({
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_API_NAME,
                endpoint: process.env.REACT_APP_API_URL,
            }
        ]
    }
});

toast.configure();

export const GetFullList = async(resource, params, token) => {
    if ( resource === 'clearcache') {
        if ( typeof GetFullList.cache !== 'undefined' ) {
            for (let key of Object.keys(GetFullList.cache)) {
                if (typeof GetFullList.cache[key] !== 'undefined' && GetFullList.cache[key].startKey === 'Done') {
                    GetFullList.cache[key] = undefined;
                }
            }
        }
        return;
    }

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const filter = params.filter;

    sortField = field
    sortOrder = order

    if ( typeof GetFullList.cache === 'undefined' ) {
        GetFullList.cache = {};
    }

    if ( typeof GetFullList.cache[resource] !== 'undefined' ) {
        if (  new Date(GetFullList.cache[resource]['validUntil']).getTime() < new Date().getTime()) {
            GetFullList.cache[resource] = undefined;
        }
    }

    if ( typeof GetFullList.cache[resource] === 'undefined' ) {
        const path = `/${resource}`;
        GetFullList.cache[resource] = {
            data: [],
            total: 0
        };

        let myInit = {
            headers: { Authorization: `Bearer ${token}` }
        }
        
        let response = await API.get(apiName, path, myInit);

        GetFullList.cache[resource] = {
            data: response.Items, 
            total: response.Total, 
            startKey: response.StartKey,
            validUntil: new Date().setTime(new Date().getTime() + (10 * 1000))
        };

        if (GetFullList.cache[resource].startKey !== 'Done') {
            let myInit = {
                queryStringParameters: {startKey: GetFullList.cache[resource].startKey},
                headers: { Authorization: `Bearer ${token}` }
            }
            toast.info("Loading "+resource+" records. ("+GetFullList.cache[resource]['total']+" of many)", 
                { position: toast.POSITION.TOP_RIGHT });
    
            API.get(apiName, path, myInit).then( async(response) => {
                let getMoreData = true;
                do {
                    GetFullList.cache[resource]['data'] = GetFullList.cache[resource]['data'].concat(response.Items);
                    GetFullList.cache[resource]['total'] = GetFullList.cache[resource]['total'] + response.Total;
                    GetFullList.cache[resource]['startKey'] = response.StartKey;
                    if (GetFullList.cache[resource]['startKey'] !== 'Done') {
                        let myInit = {
                            queryStringParameters: {startKey: GetFullList.cache[resource]['startKey']},
                            headers: { Authorization: `Bearer ${token}` }
                        }
                        toast.info("Loading "+resource+" records. ("+GetFullList.cache[resource]['total']+" of many)", 
                            { position: toast.POSITION.TOP_RIGHT });
                        response = await API.get(apiName, path, myInit);
                    } else { getMoreData = false;}
                    
                }
                while (getMoreData);
                toast.success("Loaded all "+resource+" records. ("+GetFullList.cache[resource]['total']+" of "+GetFullList.cache[resource]['total']+")", 
                    { position: toast.POSITION.TOP_RIGHT });
            })
        }
    }

    if ( GetFullList.cache[resource].total === 0 ) {
        GetFullList.filteredList = [];
    }

    if ( GetFullList.cache[resource].total > 0 ) {
        GetFullList.cache[resource].data.sort(compare);
        GetFullList.filteredList = GetFullList.cache[resource].data;

        try {
            if ( typeof filter !== 'undefined' ) {
                for (let key of Object.keys(filter)) {
                    GetFullList.filteredList = GetFullList.filteredList.filter(function(e) {
                        if (key.includes("_")) {
                            const keySplit = key.split("_");
                            if (!e[keySplit[0]]) { return false; }
                            if (!e[keySplit[0]][keySplit[1]]) { return false; }
                            return e[keySplit[0]][keySplit[1]].includes(filter[key]);
                        }
                        if (key in e) { 
                            if (typeof filter[key] == "boolean") {
                                return e[key] === filter[key];
                            } else if (typeof filter[key] == "string" && filter[key] !== "") {
                                return e[key].toLowerCase().includes(filter[key].toLowerCase());
                            } else {
                                return e[key].includes(filter[key]);
                            }
                        }                            
                        else { return false; }
                    })
                }
            };
        }
        catch(err) {
            console.log("Filter operation failed. There likely exists a record with missing data.")
        }
    };

    return {
        data: GetFullList.filteredList.slice((page - 1) * perPage, page * perPage),
        total: GetFullList.filteredList.length
    };
};

function compare(a, b) {
    if (sortField.includes(".")) {
        const sortFieldSplit = sortField.split(".");
        if (!a[sortFieldSplit[0]]) {
            var fieldA = "";
            var fieldB = b[sortFieldSplit[0]][sortFieldSplit[1]];
        } else if (!b[sortFieldSplit[0]]) {
            fieldA = a[sortFieldSplit[0]][sortFieldSplit[1]];
            fieldB = "";
        } else {
            fieldA = a[sortFieldSplit[0]][sortFieldSplit[1]];
            fieldB = b[sortFieldSplit[0]][sortFieldSplit[1]];
        }
    } else {
        fieldA = a[sortField];
        fieldB = b[sortField];
    }

    let comparison = 0;
    if (fieldA > fieldB) {
        comparison = 1;
    } else if (fieldA < fieldB) {
        comparison = -1;
    }
    if (sortOrder === "ASC") {
        return comparison;
    }
    return comparison * -1;
}