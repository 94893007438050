import React from 'react';
import { Typography, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import { Button, NullableBooleanInput, useDataProvider, useNotify } from 'react-admin';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

export default function GroupOperationButton() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const onSubmit = values => {
        dataProvider.groupoperationaction("groups", {action: "Operation", values: values}, {}).then(results => (notify(results.data)));
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} label="Operation">
                <BuildIcon />
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Group Operations</DialogTitle>
                <DialogContent>
                    <Form
                        onSubmit={onSubmit}
                        mutators={{ ...arrayMutators }} // necessary for ArrayInput
                        subscription={defaultSubscription} // don't redraw entire form each time one field changes
                        keepDirtyOnReinitialize
                        render={({handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <Typography variant="body2">Set the desired operational values.<br />
                                                            Blank fields will remain unchanged.</Typography><br />
                                    <NullableBooleanInput label="Provisions" source="provisionState" trueLabel="Enabled" falseLabel="Disabled" /><br />
                                    <NullableBooleanInput label="Terminates" source="terminateState" trueLabel="Enabled" falseLabel="Disabled" />
                                </div>
                                <div>
                                    < br/>
                                    <Button label="Update Operation" disabled={submitting} onClick={handleSubmit}><BuildIcon /></Button>
                                </div>
                                <pre>{JSON.stringify(values, 0 , 2)}</pre>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}