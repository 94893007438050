import React from 'react';
import { Typography, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import TransformIcon from '@material-ui/icons/Transform';
import { Button, useDataProvider, ReferenceInput, AutocompleteInput,
    SelectInput, FormDataConsumer, useNotify, required } from 'react-admin';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

export default function WorkspaceMigrateButton(ids) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const selectedIds = ids.ids;

    const onSubmit = values => {
        dataProvider.bulkaction("workspaces", {data: values, action: "Migrate", ids: selectedIds}, {}).then(results => (notify('Workspace Migration Request Submitted Successfully')));
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} label="Migrate">
                <TransformIcon />
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Migrate Workspace(s)</DialogTitle>
                <DialogContent>
                    <Form
                        onSubmit={onSubmit}
                        mutators={{ ...arrayMutators }} // necessary for ArrayInput
                        subscription={defaultSubscription} // don't redraw entire form each time one field changes
                        keepDirtyOnReinitialize
                        initialValues={{region: 'us-east-1'}}
                        render={({handleSubmit, form, submitting, invalid, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <Typography variant="body2">Select the bundle you wish to migrate Workspace(s) to.</Typography><br />
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => ([
                                            <SelectInput
                                                label="AWS Region"
                                                source="region"
                                                fullWidth={true}
                                                allowEmpty={false}
                                                choices={[
                                                    { id: 'us-east-1', name: 'us-east-1 (N. Virginia)'},
                                                    { id: 'us-west-2', name: 'us-west-2 (Oregon)'},
                                                    { id: 'ap-southeast-1', name: 'ap-southeast-1 (Singapore)'}
                                                ]}
                                                onChange={event => form.change('bundle_id', undefined)}
                                                variant="outlined"
                                                validate={required()}
                                            />,
                                            <ReferenceInput
                                                label="Bundle"
                                                source="bundle_id"
                                                reference="bundles"
                                                validate={required()}
                                                fullWidth={true}
                                                variant="outlined"
                                                perPage={500}
                                                sort={{ field: 'NameIdCombo', order: 'ASC' }}
                                                filter={{ region: formData.region }}
                                                filterToQuery={searchText => ({ NameIdCombo: searchText })}
                                            >
                                                <AutocompleteInput optionText="NameIdCombo" optionValue="id"
                                                    options={{suggestionsContainerProps: { style: { zIndex: 99999}}}}/>
                                            </ReferenceInput>
                                        ])}
                                    </FormDataConsumer>
                                </div>
                                    <div>
                                        < br/>
                                        <Button label="Migrate" disabled={submitting || invalid} onClick={handleSubmit}><TransformIcon /></Button>
                                    </div>
                                <pre>{JSON.stringify(values, 0 , 2)}</pre>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}