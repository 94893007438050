import * as React from "react";
import { List, Datagrid, TextField, Filter, SelectInput, TextInput  } from "react-admin";

const ImageFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Description" />
        <SelectInput label="Region" source="region" choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
        <SelectInput label="OS" source="OperatingSystem_Type" choices={[
            {id: 'LINUX', name: 'Linux'},
            {id: 'WINDOWS', name: 'Windows'}
        ]} />
        <SelectInput label="Tenancy" source="RequiredTenancy" choices={[
            {id: 'DEFAULT', name: 'DEFAULT'},
            {id: 'DEDICATED', name: 'DEDICATED'}
        ]} />
    </Filter>
);

export const ImageList = props => (
    <List {...props} filters={<ImageFilter />} bulkActionButtons={false} >
        <Datagrid>
            <TextField label="Image Id" source="id" />
            <TextField source="Description" />
            <TextField label="OS" source="OperatingSystem.Type" />
            <TextField source="region" />
            <TextField source="RequiredTenancy" />
        </Datagrid>
    </List>
);