import React, { useState } from "react";
import { TopToolbar, useDataProvider, usePermissions, Button, Confirm, useNotify } from 'react-admin';
import { RotateRight, Restore, PlayArrow, Stop, DeleteForever, Launch } from '@material-ui/icons';
import WorkspaceModifyButton from "./WorkspaceModifyButton.js"
import WorkspaceMigrateButton from "./WorkspaceMigrateButton.js"

export const WorkspaceShowActions = ({ basePath, data, resource }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { loaded, permissions } = usePermissions();

    const [rebootOpen, setRebootOpen] = useState(false);
    const [startOpen, setStartOpen] = useState(false);
    const [stopOpen, setStopOpen] = useState(false);
    const [restoreOpen, setRestoreOpen] = useState(false);
    const [rebuildOpen, setRebuildOpen] = useState(false);
    const [terminateOpen, setTerminateOpen] = useState(false);

    const handleRebootClick = () => setRebootOpen(true);
    const handleRebootDialogClose = () => setRebootOpen(false);
    const handleRebootConfirm = () => {
        dataProvider.bulkaction(resource, { ids: [data.id], action: 'Reboot' }, {}).then(results => (notify(results.data)));
        setRebootOpen(false);
    }

    const handleStartClick = () => setStartOpen(true);
    const handleStartDialogClose = () => setStartOpen(false);
    const handleStartConfirm = () => {
        dataProvider.bulkaction(resource, {ids: [data.id], action: "Start"}, {}).then(results => (notify(results.data)));
        setStartOpen(false);
    }

    const handleStopClick = () => setStopOpen(true);
    const handleStopDialogClose = () => setStopOpen(false);
    const handleStopConfirm = () => {
        dataProvider.bulkaction(resource, {ids: [data.id], action: "Stop"}, {}).then(results => (notify(results.data)));
        setStopOpen(false);
    }

    const handleRestoreClick = () => setRestoreOpen(true);
    const handleRestoreDialogClose = () => setRestoreOpen(false);
    const handleRestoreConfirm = () => {
        dataProvider.bulkaction(resource, {ids: [data.id], action: "Restore"}, {}).then(results => (notify(results.data)));
        setRestoreOpen(false);
    }

    const handleRebuildClick = () => setRebuildOpen(true);
    const handleRebuildDialogClose = () => setRebuildOpen(false);
    const handleRebuildConfirm = () => {
        dataProvider.bulkaction(resource, {ids: [data.id], action: "Rebuild"}, {}).then(results => (notify(results.data)));
        setRebuildOpen(false);
    }

    const handleTerminateClick = () => setTerminateOpen(true);
    const handleTerminateDialogClose = () => setTerminateOpen(false);
    const handleTerminateConfirm = () => {
        dataProvider.bulkaction(resource, {ids: [data.id], action: "Terminate"}, {}).then(results => (notify(results.data)));
        setTerminateOpen(false);
    }
    
    var buttonList = [];
    if (loaded) {
        if (Array.isArray(permissions) && permissions.length > 0) {
            if (permissions.includes('RebootWorkspace') || permissions.includes('Super Admin') || resource === 'myworkspace') {
                if (data.WorkspaceProperties_RunningMode === "ALWAYS_ON") {
                    buttonList = ([
                        <Button key="RebootButton" label="Reboot" onClick={handleRebootClick}><RotateRight /></Button>,
                        <Confirm
                            key="RebootConfirm"
                            isOpen={rebootOpen}
                            title="Reboot Workspace "
                            content="Are you sure you want to reboot this Workspace?"
                            onConfirm={handleRebootConfirm}
                            onClose={handleRebootDialogClose}
                        />
                    ]);
                };
            };
            if (permissions.includes('StartStopWorkspace') || permissions.includes('Super Admin') || resource === 'myworkspace') {
                if (data.WorkspaceProperties_RunningMode === "AUTO_STOP") {
                    buttonList = ([
                        <Button key="StartButton" label="Start" onClick={handleStartClick}><PlayArrow /></Button>,
                        <Confirm
                            key="StartConfirm"
                            isOpen={startOpen}
                            title="Start Workspace"
                            content="Are you sure you want to start this Workspace?"
                            onConfirm={handleStartConfirm}
                            onClose={handleStartDialogClose}
                        />,
                        <Button key="StopButton" label="Stop" onClick={handleStopClick}><Stop /></Button>,
                        <Confirm
                            key="StopConfirm"
                            isOpen={stopOpen}
                            title="Stop Workspace"
                            content="Are you sure you want to stop this Workspace?"
                            onConfirm={handleStopConfirm}
                            onClose={handleStopDialogClose}
                        />
                    ]);
                }
            }
            if (permissions.includes('RestoreWorkspace') || permissions.includes('Super Admin') || resource === 'myworkspace') {
                buttonList.push(<Button key="RestoreButton" label="Restore" onClick={handleRestoreClick}><Restore /></Button>);
                buttonList.push(
                    <Confirm
                        key="RestoreConfirm"
                        isOpen={restoreOpen}
                        title="Restore Workspace"
                        content="This will restore the workspace to it's last good snapshot. Any local data created since that snapshot will be lost. 
                        Are you sure you want to restore this Workspace?"
                        onConfirm={handleRestoreConfirm}
                        onClose={handleRestoreDialogClose}
                    />);
            }
            if (permissions.includes('RebuildWorkspace') || permissions.includes('Super Admin')) {
                buttonList.push(<Button key="RebuildButton" label="Rebuild" onClick={handleRebuildClick}><Launch /></Button>);
                buttonList.push(
                    <Confirm
                        key="RebuildConfirm"
                        isOpen={rebuildOpen}
                        title="Rebuild Workspace"
                        content="This will rebuild the workspace using the last good snapshot and current bundle. Any local data created since that snapshot will be lost. 
                        Are you sure you want to rebuild this Workspace?"
                        onConfirm={handleRebuildConfirm}
                        onClose={handleRebuildDialogClose}
                    />);
            }
            if (permissions.includes('ModifyWorkspace') || permissions.includes('Super Admin')) {
                buttonList.push(<WorkspaceModifyButton ids = {[data.id]}/>)
            }
            if (permissions.includes('MigrateWorkspace') || permissions.includes('Super Admin')) {
                buttonList.push(<WorkspaceMigrateButton ids = {[data.id]}/>)
            }
            if (permissions.includes('TerminateWorkspace') || permissions.includes('Super Admin')) {
                buttonList.push(<Button key="TerminateButton" label="Terminate" onClick={handleTerminateClick}><DeleteForever /></Button>);
                buttonList.push(
                <Confirm
                    key="TerminateConfirm"
                    isOpen={terminateOpen}
                    title="Terminate Workspace"
                    content="Are you sure you want to terminate this Workspace?"
                    onConfirm={handleTerminateConfirm}
                    onClose={handleTerminateDialogClose}
                />);
            }
        }
    }

    return (<TopToolbar>{buttonList}</TopToolbar>);
};