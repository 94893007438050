import React from 'react';
import { Typography, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import { Button, TextInput, useDataProvider, useNotify, choices } from 'react-admin';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

export default function GroupTermButton(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validateConfirm = choices(["Terminate All"], "Enter 'Terminate All' to Confirm");

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const onSubmit = () => {
        dataProvider.groupoperationaction("groups", {action: "Terminate", values: props.ids}, {}).then(results => (notify(results.data)));
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} label="Terminate All">
                <DeleteSweepIcon />
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Terminate All Workspaces</DialogTitle>
                <DialogContent>
                    <Form
                        onSubmit={onSubmit}
                        mutators={{ ...arrayMutators }} // necessary for ArrayInput
                        subscription={defaultSubscription} // don't redraw entire form each time one field changes
                        keepDirtyOnReinitialize
                        render={({handleSubmit, form, submitting, pristine, invalid, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <Typography variant="body2">You are about to terminate all workspaces for {props.ids.length} group(s)</Typography><br />
                                    <Typography variant="body2">All workspaces with matching DirectoryGroup tag and Automation=Managed tag will be terminated.</Typography><br />
                                    <Typography variant="body2">It is recommended that these groups be disabled first, or workspaces may be recreated.</Typography><br />
                                    <TextInput label="Enter 'Terminate All' to Confirm" source="confirm" validate={validateConfirm} fullWidth={true}/><br />
                                </div>
                                <div>
                                    < br/>
                                    <Button label="Terminate All" disabled={submitting | pristine | invalid} onClick={handleSubmit}><DeleteSweepIcon /></Button>
                                </div>
                                <pre>{JSON.stringify(values, 0 , 2)}</pre>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}