import * as React from "react";
import { List, Datagrid, TextField, DateField, Filter, SelectInput, TextInput  } from "react-admin";

const PendingFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Status" source="Status" choices={[
            {id: 'NEW', name: 'New'},
            {id: 'TERMINATE', name: 'Terminate'},
            {id: 'PROVISIONING', name: 'Provisioning'},
            {id: 'TERMINATING', name: 'Terminating'},
            {id: 'RETRY', name: 'Retry'},
            {id: 'FAILED', name: 'Failed'}
        ]} />
        <SelectInput label="Action" source="Action" choices={[
            {id: 'Provision', name: 'Provision'},
            {id: 'Terminate', name: 'Terminate'}
        ]} />
        <TextInput source="UserName" />
        <SelectInput label="Region" source="region" choices={[
            {id: 'us-east-1', name: 'us-east-1'},
            {id: 'us-west-2', name: 'us-west-2'},
            {id: 'eu-west-1', name: 'eu-west-1'},
            {id: 'ap-southeast-1', name: 'ap-southeast-1'}
        ]} />
        <TextInput source="DirectoryGroup" />
        <TextInput source="workspaceId" />
    </Filter>
);

export const PendingList = props => (
    <List {...props} title = "Provisioning Actions" filters={<PendingFilter />} bulkActionButtons={false} >
        <Datagrid>
            <DateField source="JobCreated" showTime options={{timeZoneName: 'short'}}/>
            <TextField source="Status" />
            <TextField source="Action" />
            <TextField source="UserName" />
            <TextField source="region" />
            <TextField source="DirectoryGroup" />
            <TextField source="workspaceId" />
            <TextField source="errordetails" />
        </Datagrid>
    </List>
);